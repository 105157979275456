import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ProjectCard title="Ksiegowość" mdxType="ProjectCard">
Obsługa księgowa firm
    </ProjectCard>
    <ProjectCard title="Doradztwo" mdxType="ProjectCard">
Doradztwo podatkowe
    </ProjectCard>
    <ProjectCard title="Ulgi mieszkaniowe" link="https://www.money.pl/gospodarka/wiadomosci/artykul/ulga-meldunkowa-skarbowka-henryk-blasik-nsa,220,0,2403036.html" mdxType="ProjectCard">
Nieruchomości
    </ProjectCard>
    <ProjectCard title="Optymalizacja" mdxType="ProjectCard">
Analiza dostępnych opcji
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      