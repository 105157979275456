import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div style={{
      textAlign: 'center'
    }}>  
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2497.272801293343!2d22.53376695129301!3d51.25088633714676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x472259dee81d5293%3A0x331240ab1aada109!2sDoradca%20Podatkowy%20Grzegorz%20Sadura!5e0!3m2!1spl!2spl!4v1569452820942!5m2!1spl!2spl" width="800" height="600" frameBorder="0" style={{
        "border": "0"
      }} allowFullScreen=""></iframe>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      